<template>
  <div class="container pricing">
    <IndexHeader></IndexHeader>
    <section class="section-pricing">
      <div class="section-header">
        <h1 class="title">按需付费，一切为您！</h1>
        <p class="desc">
          锐研根据客户需求，提供灵活可定制的云统计平台建设服务。
        </p>
      </div>
      <!-- 版本价格表start -->
      <table
        class="version"
        style="table-layout: fixed"
        width="100%"
        cellspacing="0"
        cellpadding="2"
      >
        <tr class="th" style="line-height: 28px">
          <td>
            版本
            <br />
            定价
          </td>
          <td>
            体验版
            <br />
            <span style="color: #5eaf10; font-size: 20px">免费</span>
          </td>
          <td>
            标准版
            <br />
            <span class="text">￥980</span>
            <span style="font-size: 14px">/年</span>
          </td>
          <td>
            高级版
            <br />
            <span class="text">￥1980</span>
            <span style="font-size: 14px">/年</span>
          </td>
          <td>
            定制版
            <br />
            <span style="color: #f2832a; font-size: 20px">按需定制</span>
          </td>
        </tr>
        <tr style="background-color: #e5e5e5">
          <td colspan="5" class="base1">各版本功能区别</td>
          <td colspan="4" class="base2">各版本功能区别</td>
        </tr>
        <tr>
          <td>统计算法类型</td>
          <td>基础</td>
          <td>标准</td>
          <td>高级</td>
          <td>高级</td>
        </tr>
        <tr>
          <td>数据存储时间</td>
          <td>一年</td>
          <td>有效期内永久</td>
          <td>有效期内永久</td>
          <td>永久</td>
        </tr>
        <tr>
          <td>变量数量限制</td>
          <td>20个</td>
          <td>100个</td>
          <td>500个</td>
          <td>不限</td>
        </tr>
        <tr>
          <td>最大分析记录</td>
          <td>500条</td>
          <td>5000条</td>
          <td>50000条</td>
          <td>不限</td>
        </tr>
        <tr>
          <td>分析结果自定义可视化</td>
          <td>
            <el-icon><SuccessFilled /></el-icon>
          </td>
          <td>
            <el-icon><SuccessFilled /></el-icon>
          </td>
          <td>
            <el-icon><SuccessFilled /></el-icon>
          </td>
          <td>
            <el-icon><SuccessFilled /></el-icon>
          </td>
        </tr>
        <tr>
          <td>子数据集</td>
          <td></td>
          <td>
            <el-icon><SuccessFilled /></el-icon>
          </td>
          <td>
            <el-icon><SuccessFilled /></el-icon>
          </td>
          <td>
            <el-icon><SuccessFilled /></el-icon>
          </td>
        </tr>
        <tr>
          <td>数据集共享</td>
          <td></td>
          <td>
            <!-- <el-icon><SuccessFilled /></el-icon> -->
          </td>
          <td>
            <el-icon><SuccessFilled /></el-icon>
          </td>
          <td>
            <el-icon><SuccessFilled /></el-icon>
          </td>
        </tr>
        <tr>
          <td>多人协作分析</td>
          <td></td>
          <td></td>
          <td>
            <el-icon><SuccessFilled /></el-icon>
          </td>
          <td>
            <el-icon><SuccessFilled /></el-icon>
          </td>
        </tr>
        <tr>
          <td>私有云部署</td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <el-icon><SuccessFilled /></el-icon>
          </td>
        </tr>
        <tr class="end">
          <td></td>
          <td>
            <el-button
              style="border-color: #f27800; color: #f27800"
              @click="onRegister"
              round
            >
              立即体验
            </el-button>
          </td>
          <td>
            <el-button
              style="border-color: #f27800; color: #f27800"
              @click="onCreateOrder(15)"
              round
            >
              立即购买
            </el-button>
          </td>
          <td>
            <el-button
              style="border-color: #f27800; color: #f27800"
              @click="onCreateOrder(16)"
              round
            >
              立即购买
            </el-button>
          </td>
          <td>
            <el-button
              style="border-color: #f27800; color: #f27800"
              round
              @click="centerDialogVisible = true"
            >
              立即咨询
            </el-button>
          </td>
        </tr>
      </table>
      <!-- 2个套餐展示框 -->
      <div class="price" v-if="!isToMore">
        <div class="title">套餐定价</div>
        <div class="content">
          <table
            style="table-layout: fixed"
            width="100%"
            cellspacing="0"
            cellpadding="2"
          >
            <tr>
              <td class="tit">套餐名</td>
              <td class="tit">说明</td>
              <td class="tit">售价</td>
              <td rowspan="3">
                <el-button type="warning" class="btn" @click="toMore">
                  更多套餐
                </el-button>
              </td>
            </tr>
            <tr>
              <td>统计调查套餐</td>
              <td>云调查+云统计</td>
              <td>
                <el-row :gutter="10">
                  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                    <div>
                      <span class="yuan">￥3980</span>
                      <span>/年</span>
                    </div>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                    <div class="original">
                      原价：
                      <span class="ins">￥4960/年</span>
                    </div>
                  </el-col>
                </el-row>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>小数据套餐</td>
              <td>云调查+云访谈</td>
              <td>
                <el-row :gutter="10">
                  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                    <div>
                      <span class="yuan">￥4780</span>
                      /年
                    </div>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                    <div>
                      <span class="original">
                        原价：
                        <span class="ins">￥5960/年</span>
                      </span>
                    </div>
                  </el-col>
                </el-row>
              </td>
              <td></td>
            </tr>
          </table>
        </div>
      </div>
      <div class="more-btn" v-if="!isToMore">
        <el-row type="flex" justify="center">
          <el-button type="warning" class="btn" @click="toMore">
            更多套餐
          </el-button>
        </el-row>
      </div>
      <!-- 套餐定价end -->
      <!-- 立即咨询弹框start -->
      <el-dialog
        title="立即咨询"
        :v-model="centerDialogVisible"
        width="30%"
        center
      >
        <p style="font-size: 18px; font-weight: 400">
          如果您想进一步了解，请联系我们
        </p>
        <p>
          服务热线：
          <span style="color: #f2832a; font-size: 18px">4006-213-056</span>
        </p>
        <p>
          南京客户支持中心：
          <span style="color: #f2832a; font-size: 18px">025-86207696</span>
        </p>
      </el-dialog>
      <!-- 立即咨询弹框end -->
    </section>
    <BaseFooter></BaseFooter>
  </div>
</template>

<script>
import IndexHeader from "@/components/layout/IndexHeader.vue";
import BaseFooter from "@/components/layout/BaseFooter.vue";
import { useUserInfo } from "@/utils/useUserInfo";
import { httpPost } from "@/api/httpService.js";
import { goLogin } from "@/utils/login.js";
import VueCookies from "vue-cookies";

const { name } = useUserInfo();

export default {
  data() {
    return {
      centerDialogVisible: false, //立即咨询弹框
      isLogin: false, // 用户是否登录
    };
  },
  created() {
    // console.log("pricing", this);
    /*判断用户是否登录*/
    if (VueCookies.isKey("tokenWeb")) {
      let userInfo = VueCookies.get("userInfo");
      this.userName = userInfo.name;
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
  },
  components: {
    IndexHeader,
    BaseFooter,
  },
  methods: {
    /**
     * 更多套餐
     */
    toMore() {
      window.open("https://www.ringdata.com/package");
    },
    /*点击立即体验事件*/
    onRegister() {
      if (name) {
        this.$router.push({
          path: "/project",
        });
      } else {
        // this.$router.push({
        //   path: "/login",
        // });
        goLogin();
      }
    },
    /*点击立即购买事件*/
    onCreateOrder(id) {
      if (this.isLogin) {
        window.open(`https://ringdata.com/createOrder?st=${id}`);
      } else {
        this.$message.info("您还未登录，请登录后购买使用");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
}
.section-pricing {
  padding: 50px 20px 10px;
  max-width: 1120px;
  margin: 70px auto 0;
}
.section-header {
  margin-bottom: 60px;
  .title {
    text-align: center;
    color: #000000;
    font-size: 34px;
    font-weight: 300;
    margin-bottom: 30px;
  }
  .desc {
    text-align: center;
    color: grey;
    font-size: 16px;
    font-weight: 300;
  }
}
// 版本价格表格start
// 版本价格表格start
$base-color: #f2832a;
.btn {
  background: $base-color;
}
.btn:hover {
  background: #f89543;
}
.more-btn {
  display: none;
}
.version {
  width: 1080px;
  height: 300px;
  text-align: center;
  line-height: 40px;
  border: 1px solid #cbcbcb;
  border-collapse: collapse; /* 合并单元格 */
  .base2 {
    display: none;
  }
  td {
    border: 1px solid #cbcbcb;
  }
  .th {
    text-align: center !important;
  }
  .th td {
    padding: 18px 0px;
    background: #fff !important;
    font-size: 20px;
    font-weight: 300;
    color: #000;
    .text {
      color: #f2832a;
      font-size: 20px;
    }
  }
  .end td {
    padding: 12px 0px;
    background: #fff !important;
  }
  tr td:nth-child(3),
  tr td:nth-child(5) {
    background: #f3f3f3;
  }
  //   tr td:nth-child(1){
  //       text-align: left;
  //       padding-left: 12px;
  //   }
  td button:hover {
    background-color: hsl(27, 89%, 82%);
  }
}
.el-icon {
  color: $base-color;
  font-size: 18px;
  line-height: 20px;
  position: relative;
  top: 5px;
}
//2个套餐展示框
.price {
  height: 100%;
  width: 100%;
  margin: 12px 0px;
  tr td:nth-child(1),
  tr td:nth-child(2),
  tr td:nth-child(3) {
    text-align: left;
    width: 30%;
  }
  tr td:nth-child(4) {
    width: 10%;
  }
  .title {
    margin-bottom: 12px;
  }
  .title,
  .yuan {
    font-size: 18px;
    color: $base-color;
  }
  .content {
    width: 100%;
    height: 100%;
    padding-top: 12px;
    line-height: 40px;
    padding: 0px 12px;
    border: 1px solid #cbcbcb;
    .tit {
      font-size: 16px;
      font-weight: 600;
      color: #999;
      padding-left: 24px;
    }
    .lh {
      height: 60px;
      line-height: 60px;
    }
  }
  .original {
    font-size: 12px;
    color: #dfdfdf;
    .ins {
      text-decoration: line-through;
    }
  }
}
//手机自适应
@media screen and (max-width: 767px) {
  tr td:nth-child(5) {
    display: none;
  }
  .section-header {
    margin-bottom: 24px !important;
  }
  .version {
    width: 100%;
    line-height: 24px;
    .el-button {
      padding: 2px;
      font-size: 12px !important;
    }
    .base1 {
      display: none;
    }
    tr td:nth-child(1) {
      width: 60px;
    }
  }
  .price {
    margin-bottom: 12px;
    .content {
      height: 100%;
      line-height: 24px;
    }
    table {
      font-size: 12px;
      tr td:nth-child(4) {
        display: none;
      }
    }
    .btn {
      margin-left: 130%;
    }
  }
  .more-btn {
    display: block;
  }
  .content {
    padding-top: 12px;
    height: 100px;
    line-height: 40px;
    padding-left: 24px;
    border: solid 1px #cbcbcb;
    .col-one {
      width: 231px;
      float: left;
    }
    .col-two {
      width: 416px;
      float: left;
    }
    .col-three {
      width: 323px;
      float: left;
    }
    .col-four {
      width: 93px;
      float: left;
    }
  }
  .original {
    padding-left: 24px;
    font-size: 12px;
    color: #dfdfdf;
  }
}
//套餐定价end
</style>

<style lang="scss" scoped>
@import url("../../assets/styles/responsive-index.scss");
.pricing {
  .header-content {
    max-width: 1280px;
    margin: 0 auto;
    padding: 5px 20px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    img {
      position: absolute;
      left: 20px;
      height: 55px;
    }
    a {
      color: #777777;
    }
    .nav {
      margin-right: 30px;
      li {
        display: inline-block;
        margin-right: 20px;
        cursor: pointer;
        &.active {
          a {
            color: #f27800 !important;
          }
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
</style>
